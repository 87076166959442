import Exercise from "./controllers/Exercise/Exercise.jsx";
import ExerciseAdd from "./controllers/Exercise/ExerciseAdd.jsx";
import ExerciseEdit from "./controllers/Exercise/ExerciseEdit";
import Info from "./controllers/Info";
import User from "./controllers/User/User";
import UserPaymentInfo from "./controllers/User/UserPaymentInfo";
import ExerciseTranslation from "./controllers/Translation/Exercise/Exercise";
import ExerciseTranslationAdd from "./controllers/Translation/Exercise/ExerciseAdd";
import ExerciseTranslationEdit from "./controllers/Translation/Exercise/ExerciseEdit";
import BodyPartTranslationAdd from "./controllers/Translation/BodyPart/BodyPartAdd";
import BodyPartTranslation from "./controllers/Translation/BodyPart/BodyPart";
import BodyPartTranslationEdit from "./controllers/Translation/BodyPart/BodyPartEdit";
import defaults from "./defaults";
import Workout from "./controllers/Workout/Workout";
import WorkoutAdd from "./controllers/Workout/WorkoutAdd";
import WorkoutEdit from "./controllers/Workout/WorkoutEdit";
import WorkoutBlockListEdit from "./controllers/Workout/Block/WorkoutBlockListEdit";
import Block from "./controllers/Block/Block";
import BlockAdd from "./controllers/Block/BlockAdd";
import BlockEdit from "./controllers/Block/BlockEdit";
import Tag from "./controllers/Tag/Tag";
import TagAdd from "./controllers/Tag/TagAdd";
import TagEdit from "./controllers/Tag/TagEdit";
import AudioTrackCategory from "./controllers/Translation/AudioTrack/Category/AudioTrackCategory";
import AudioTrackCategoryAdd from "./controllers/Translation/AudioTrack/Category/AudioTrackCategoryAdd";
import AudioTrackCategoryEdit from "./controllers/Translation/AudioTrack/Category/AudioTrackCategoryEdit";
import AudioTrack from "./controllers/Translation/AudioTrack/AudioTrack";
import AudioTrackAdd from "./controllers/Translation/AudioTrack/AudioTrackAdd";
import AudioTrackEdit from "./controllers/Translation/AudioTrack/AudioTrackEdit";
import AudioTrackTranslation from "./controllers/Translation/AudioTrack/AudioTrackTranslation";
import TrainingPlanPhase from "./controllers/TrainingPlan/Phase/TrainingPlanPhase";
import TrainingPlanPhaseAdd from "./controllers/TrainingPlan/Phase/TrainingPlanPhaseAdd";
import TrainingPlanPhaseEdit from "./controllers/TrainingPlan/Phase/TrainingPlanPhaseEdit";
import TrainingPlan from "./controllers/TrainingPlan/TrainingPlan";
import TrainingPlanAdd from "./controllers/TrainingPlan/TrainingPlanAdd";
import TrainingPlanEdit from "./controllers/TrainingPlan/TrainingPlanEdit";
import TrainingPlanFitnessComponent from "./controllers/TrainingPlan/FitnessComponent/TrainingPlanFitnessComponent";
import TrainingPlanFitnessComponentAdd from "./controllers/TrainingPlan/FitnessComponent/TrainingPlanFitnessComponentAdd";
import TrainingPlanFitnessComponentEdit from "./controllers/TrainingPlan/FitnessComponent/TrainingPlanFitnessComponentEdit";
import FitnessComponentTranslation from "./controllers/Translation/FitnessComponent/FitnessComponent";
import FitnessComponentTranslationAdd from "./controllers/Translation/FitnessComponent/FitnessComponentAdd";
import FitnessComponentTranslationEdit from "./controllers/Translation/FitnessComponent/FitnessComponentEdit";
import BodyRecomposition from "./controllers/TrainingPlan/BodyRecomposition/BodyRecomposition";
import BodyRecompositionAdd from "./controllers/TrainingPlan/BodyRecomposition/BodyRecompositionAdd";
import BodyRecompositionEdit from "./controllers/TrainingPlan/BodyRecomposition/BodyRecompositionEdit";
import BodyRecompositionTranslation from "./controllers/Translation/BodyRecomposition/BodyRecomposition";
import BodyRecompositionTranslationAdd from "./controllers/Translation/BodyRecomposition/BodyRecompositionAdd";
import BodyRecompositionTranslationEdit from "./controllers/Translation/BodyRecomposition/BodyRecompositionEdit";

let g = defaults.userGroups;

const dashboardRoutes = [
  {
    exact: true,
    path: "/exercises",
    name: "Exercises",
    icon: "pe-7s-check",
    component: Exercise,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/exercises/add",
    name: "Add Exercise",
    icon: "pe-7s-check",
    component: ExerciseAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/exercises/edit/:id",
    name: "Edit Exercise",
    icon: "pe-7s-check",
    component: ExerciseEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Workouts
  {
    exact: true,
    path: "/workouts",
    name: "Workouts",
    icon: "pe-7s-gym",
    component: Workout,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/workouts/add",
    name: "Workout Add",
    icon: "pe-7s-gym",
    component: WorkoutAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/workouts/edit/:id",
    name: "Workout Edit",
    icon: "pe-7s-gym",
    component: WorkoutEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    exact: true,
    invisible: true,
    path: "/workouts/:id/blocks",
    name: "Workout Blocks",
    icon: "pe-7s-gym",
    component: WorkoutBlockListEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  // Training Plans
  {
    exact: true,
    path: "/training-plans",
    name: "Training Plans",
    icon: "pe-7s-check",
    component: TrainingPlan,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },

  {
    path: "/training-plans/add",
    name: "Training Plan Add",
    component: TrainingPlanAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/training-plans/edit/:id",
    name: "Training Plan Edit",
    component: TrainingPlanEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Training Plans Phases
  {
    exact: true,
    submenu: true,
    path: "/training-plans/phases",
    name: "Plan Phases",
    component: TrainingPlanPhase,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/training-plans/phases/add",
    name: "Plan Phases Add",
    component: TrainingPlanPhaseAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/training-plans/phases/edit/:id",
    name: "Plan Phases Edit",
    component: TrainingPlanPhaseEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Fitness Components
  {
    exact: true,
    submenu: true,
    path: "/training-plans/fitness-components",
    name: "Fitness Components",
    component: TrainingPlanFitnessComponent,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/training-plans/fitness-components/add",
    name: "Plan Fitness Component Add",
    component: TrainingPlanFitnessComponentAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/training-plans/fitness-components/edit/:id",
    name: "Plan Fitness Component Edit",
    component: TrainingPlanFitnessComponentEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Body Recompositions
  {
    exact: true,
    submenu: true,
    path: "/training-plans/body-recompositions",
    name: "Body Recompositions",
    component: BodyRecomposition,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/training-plans/body-recompositions/add",
    name: "Plan Body Recomposition Add",
    component: BodyRecompositionAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/training-plans/body-recompositions/edit/:id",
    name: "Plan Body Recomposition Edit",
    component: BodyRecompositionEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Blocks
  {
    exact: true,
    path: "/blocks",
    name: "Blocks",
    icon: "pe-7s-box2",
    component: Block,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/blocks/add",
    name: "Block Add",
    icon: "pe-7s-gym",
    component: BlockAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/blocks/edit/:id",
    name: "Block Edit",
    icon: "pe-7s-gym",
    component: BlockEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Tags
  {
    exact: true,
    submenu: true,
    path: "/blocks/tags",
    name: "Tags",
    component: Tag,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/blocks/tags/add",
    name: "Tag Add",
    icon: "pe-7s-gym",
    component: TagAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/blocks/tags/edit/:id",
    name: "Tag Edit",
    icon: "pe-7s-gym",
    component: TagEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Translations exercises
  {
    exact: true,
    path: "/translations/exercises",
    name: "Translations",
    icon: "pe-7s-pen",
    component: ExerciseTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    exact: true,
    submenu: true,
    path: "/translations/exercises",
    name: "Exercises",
    component: ExerciseTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/translations/exercises/add",
    name: "",
    component: ExerciseTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/translations/exercises/edit/:id",
    name: "",
    component: ExerciseTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Translations Body parts
  {
    exact: true,
    submenu: true,
    path: "/translations/body-parts",
    name: "Body Parts",
    component: BodyPartTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/translations/body-parts/add",
    name: "",
    component: BodyPartTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/translations/body-parts/edit/:id",
    name: "",
    component: BodyPartTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Translations fitness components
  {
    exact: true,
    submenu: true,
    path: "/translations/fitness-components",
    name: "Fitness Components",
    component: FitnessComponentTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/translations/fitness-components/add",
    name: "",
    component: FitnessComponentTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/translations/fitness-components/edit/:id",
    name: "",
    component: FitnessComponentTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Translations fitness components
  {
    exact: true,
    submenu: true,
    path: "/translations/body-recompositions",
    name: "Body Recompositions",
    component: BodyRecompositionTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/translations/body-recompositions/add",
    name: "",
    component: BodyRecompositionTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/translations/body-recompositions/edit/:id",
    name: "",
    component: BodyRecompositionTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Audio Track
  {
    exact: true,
    invisible: true,
    path: "/translations/audio-tracks/:id",
    name: "Audio Track Translations",
    component: AudioTrackTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  // Audio Track Category
  {
    exact: true,
    submenu: true,
    path: "/audio-tracks/categories",
    name: "Audio Tracks",
    component: AudioTrackCategory,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/audio-tracks/categories/add",
    name: "",
    component: AudioTrackCategoryAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/audio-tracks/categories/edit/:id",
    name: "",
    component: AudioTrackCategoryEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Audio Track
  {
    exact: true,
    invisible: true,
    path: "/audio-tracks",
    name: "Audio Tracks",
    component: AudioTrack,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/audio-tracks/add",
    name: "",
    component: AudioTrackAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/audio-tracks/edit/:id",
    name: "",
    component: AudioTrackEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Users
  {
    exact: true,
    path: "/users",
    name: "Users",
    icon: "pe-7s-user",
    component: User,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
  {
    invisible: true,
    path: "/users/:id/payment-info",
    name: "User Payment Info",
    icon: "pe-7s-user",
    component: UserPaymentInfo,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
  {
    path: "/info",
    name: "Info",
    icon: "pe-7s-info",
    component: Info,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
];

export default dashboardRoutes;
